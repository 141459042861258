import "@fontsource/open-sans";
import "@fontsource/raleway";

import { extendTheme } from "@chakra-ui/react";

const getColours = (): Record<string, string> => {
  const themeOverride = localStorage.getItem("themeOverride");

  if (themeOverride === "xmas") {
    return {
      kpmgblue: "#e60031",
      kpmgcobalt: "#6c001a",
      kpmgdarkblue: "#6c001a",
      kpmglightblue: "#e60031",
      kpmgpacificblue: "#e60031",
      kpmgpurple: "#00be9c",
      kpmgpink: "#005f54",
    };
  }

  if (themeOverride === "unicorn") {
    return {
      kpmgblue: "#a3c4f3",
      kpmgcobalt: "#90dbf4",
      kpmgdarkblue: "#8eecf5",
      kpmglightblue: "#98f5e1",
      kpmgpacificblue: "#ffcfd2",
      kpmgpurple: "#cfbaf0",
      kpmgpink: "#f1c0e8",
    };
  }

  return {
    kpmgblue: "#00338D",
    kpmgcobalt: "#1E49E2",
    kpmgdarkblue: "#0C233C",
    kpmglightblue: "#ACEAFF",
    kpmgpacificblue: "#00B8F5",
    kpmgpurple: "#7213EA",
    kpmgpink: "#FD349C",
  };
};

export const theme = extendTheme({
  colors: getColours(),
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
});
