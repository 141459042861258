import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { QueryClient } from "@tanstack/react-query";
import {
  addDays,
  format,
  formatISO,
  interval,
  isBefore,
  isValid,
  isWithinInterval,
  parseISO,
  subDays,
} from "date-fns";
import { isSupported, setUserId, setUserProperties } from "firebase/analytics";
import { isValidElement } from "react";
import { RiLock2Line, RiUserLine } from "react-icons/ri";

import { getFirebaseAnalytics } from "./Firebase";
import {
  BlobContent,
  Session,
  SessionResponse,
  SimpleUser,
  TextContent,
} from "./index.d";
import { JwtData } from "./Provider/AuthProvider";

const clickthroughKey = "quarterlySurveyClickthrough";
const remindKey = "quarterlySurveyRemind";

export const strToTitle = (string: string): string => {
  return string
    .split("_")
    .filter((x: string) => x.length > 0)
    .map((x: string) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
};

export const getFileName = (str: string): string => {
  return str
    .replaceAll(" ", "_")
    .replace(/_+/g, "_")
    .replace(/\W/g, "")
    .toLowerCase();
};

export const getSessionFileName = (
  session: Session | SessionResponse,
): string => {
  return getFileName(getSessionName(session));
};

export const getSessionName = (session: Session | SessionResponse): string => {
  const maxLen = 100;
  if (session.name !== "") {
    return session.name;
  }

  if (Object.keys(session.history).length > 0) {
    const filteredKeys = Object.keys(session.history).filter(
      (messagePairId) => {
        const message = session.history[messagePairId];
        const isHidden =
          "hidden" in message.bot.meta && message.bot.meta.hidden;

        return !isHidden;
      },
    );

    if (filteredKeys.length > 0) {
      const key = filteredKeys[0];

      const firstMessage = session.history[key].user;
      if (
        firstMessage.message_type === "text" &&
        firstMessage?.content?.content?.length
      ) {
        return (firstMessage.content as TextContent).content.substring(
          0,
          maxLen,
        );
      }
    }
  }

  return "New Session";
};

export const reactNodeToString = (reactNode: React.ReactNode): string => {
  if (typeof reactNode === "string") {
    return reactNode;
  }

  if (typeof reactNode === "number") {
    return reactNode.toString();
  }

  if (typeof reactNode === "boolean") {
    return reactNode.toString();
  }

  if (reactNode === undefined || reactNode === null) {
    return "";
  }

  if (reactNode instanceof Array) {
    return reactNode.map((node) => reactNodeToString(node)).join("\n");
  }

  if (isValidElement(reactNode)) {
    return reactNodeToString(reactNode.props.children);
  }

  throw new Error(
    `Cannot convert node (${typeof reactNode}) to string: ${reactNode}`,
  );
};

export const downloadFile = (file: Blob, fileName: string) => {
  downloadUri(URL.createObjectURL(file), fileName);
};

export const downloadUri = (uri: string, fileName: string) => {
  const link = document.createElement("a");
  link.download = fileName;
  link.href = uri;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const getAverageRequestTimeMs = (): number => {
  const key = "requestTimes";
  const requestTimes = localStorage.getItem(key);
  const requestTimesArr = requestTimes ? JSON.parse(requestTimes) : [];

  const avgRequestTime =
    requestTimesArr.length > 0
      ? requestTimesArr.reduce((p: number, c: number) => p + c, 0) /
        requestTimesArr.length
      : 0;

  return Math.round(avgRequestTime * 100) / 100;
};

export const downloadDebugFile = (
  jwtData: JwtData | null,
  user: SimpleUser | undefined,
  currentSession: SessionResponse | undefined,
) => {
  const displayUser: Partial<SimpleUser> = { ...user };

  const displaySession: Partial<SessionResponse> = currentSession ?? {};

  let debugLog = `
Version
=======

${appVersion}

User
====

${JSON.stringify(displayUser, null, 2)}

Permissions
===========

${jwtData && JSON.stringify(jwtData.permissions, null, 2)}

JWT Data
========

${JSON.stringify(jwtData, null, 2)}

JWT Expiry
==========

${jwtData === null ? "-" : format(jwtData.expiry, "do LLL y HH:mm:ss")}

JWT Refresh At
==============

${jwtData === null ? "-" : format(jwtData.refreshAt, "do LLL y HH:mm:ss")}

Average Request Time
====================

${getAverageRequestTimeMs()} ms
`;
  if (displaySession) {
    debugLog += `

Current Session
===============

${JSON.stringify(displaySession, null, 2)}
`;
  }

  downloadFile(
    new Blob([debugLog], {
      type: "text/plain",
    }),
    "ava-debug.txt",
  );
};

export const blobContentToBlob = (blobContent: BlobContent): Blob => {
  const sliceSize = 512;

  const byteCharacters = atob(blobContent.bytes_base64_encoded);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: blobContent.mime_type });
  return blob;
};

export const poll = async <T>(
  fn: () => Promise<T>,
  fnCondition: (data: T) => boolean,
  msBetweenAttempts: number,
  maxTimeMs: number = 30000, // 30 seconds
): Promise<T | null> => {
  const startTime = new Date().getTime();

  let result = await fn();
  while (!fnCondition(result)) {
    if (new Date().getTime() > startTime + maxTimeMs) {
      logger("poll").error(`Polling timed out after ${maxTimeMs} ms`);
      return null;
    }

    await new Promise((resolve) => {
      setTimeout(resolve, msBetweenAttempts);
    });
    result = await fn();
  }

  return result;
};

export const appVersion: string = import.meta.env.VITE_VERSION || "dev";
export const isDev = appVersion === "dev";
export const isNonProd = import.meta.env.VITE_ENV_TYPE === "non-prod" || isDev;

type LoggerMethods = "debug" | "log" | "warn" | "error";

const shouldLog = (name: string, method: string): boolean => {
  const nameFilter: string[] = [
    /* Use this to filter out any noise from logs*/
    // "sessions",
    // "jobs",
  ];

  if (nameFilter.includes(name)) {
    return false;
  }

  if (isDev) {
    return true;
  }

  if (isNonProd) {
    return true;
  }

  if (["warn", "error"].includes(method)) {
    return true;
  }

  return false;
};

const shouldLogDatadog = (): boolean => {
  if (isDev) {
    return false;
  }

  if (isNonProd) {
    return true;
  }

  return false;
};

const log = (
  name: string = "ava",
  color: string = "red",
  method: LoggerMethods,
  ...args: unknown[]
) => {
  if (!shouldLog(name, method)) {
    return;
  }

  const styles = [
    `background: ${color!}`,
    `border-radius: 0.5em`,
    `color: white`,
    `font-weight: bold`,
    `padding: 2px 0.5em`,
  ];

  const logPrefix = [`%c${name}`, styles.join(";")];

  console[method](...logPrefix, ...args);

  if (shouldLogDatadog()) {
    datadogLogs.logger[method](args.join(", "));
  }
};

export type Logger = {
  debug: (...args: unknown[]) => void;
  log: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
};

export const logger = (
  name: string = "ava",
  color: string = "gray",
): Logger => {
  const _log = (method: LoggerMethods, ...args: unknown[]) => {
    log(name, color, method, ...args);
  };

  return {
    debug: (...args: unknown[]) => _log("debug", ...args),
    log: (...args: unknown[]) => _log("log", ...args),
    warn: (...args: unknown[]) => _log("warn", ...args),
    error: (...args: unknown[]) => _log("error", ...args),
  };
};

export const banner = () => {
  const consoleLines = [
    "🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈",
    "",
    "🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥",
    "🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥⬛⬛⬛🟥🟥🟥⬛⬛⬛⬛⬛⬛⬛⬛🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥⬛⬛⬛⬛⬛⬛⬛⬛🟥🟥🟥⬛⬛⬛🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥",
    "🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥⬛⬜⬜⬜⬛🟥🟥⬛⬜⬜⬜⬜⬜⬜⬛🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥⬛⬜⬜⬜⬜⬜⬜⬛🟥🟥⬛⬜⬜⬜⬛🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥",
    "🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧⬛⬜⬜⬜⬜⬜⬛🟧⬛⬜⬜⬜⬜⬜⬜⬛🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧⬛⬜⬜⬜⬜⬜⬜⬛🟧⬛⬜⬜⬜⬜⬜⬛🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧",
    "🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧⬛⬜⬜⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬜⬛🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧⬛⬜⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬜⬜⬛🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧",
    "🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧⬛⬜⬜⬜⬜⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬜⬜⬜⬜⬛🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧",
    "🟨🟨🟨🟨🟨🟨🟨🟨🟨🟨⬛⬜⬜⬜⬜⬜⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟨🟨🟨🟨🟨🟨🟨🟨🟨⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛⬜⬜⬜⬜⬜⬛🟨🟨🟨🟨🟨🟨🟨🟨🟨🟨",
    "🟨🟨🟨🟨🟨🟨🟨🟨🟨⬛⬜⬜⬜⬜⬜⬛🟨⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟨🟨🟨🟨🟨🟨🟨⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟨⬛⬜⬜⬜⬜⬜⬛🟨🟨🟨🟨🟨🟨🟨🟨🟨",
    "🟨🟨🟨🟨🟨🟨🟨🟨⬛⬜⬜⬜⬜⬜⬛🟨🟨🟨⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟨🟨🟨🟨🟨⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟨🟨🟨⬛⬜⬜⬜⬜⬜⬛🟨🟨🟨🟨🟨🟨🟨🟨",
    "🟩🟩🟩🟩🟩🟩🟩⬛⬜⬜⬜⬜⬜⬛🟩🟩🟩🟩🟩⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟩🟩🟩⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟩🟩🟩🟩🟩⬛⬜⬜⬜⬜⬜⬛🟩🟩🟩🟩🟩🟩🟩",
    "🟩🟩🟩🟩🟩🟩⬛⬜⬜⬜⬜⬜⬛⬛⬛⬛⬛⬛⬛⬛⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟩⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛⬛⬛⬛⬛⬛⬛⬛⬛⬜⬜⬜⬜⬜⬛🟩🟩🟩🟩🟩🟩",
    "🟩🟩🟩🟩🟩⬛⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬛🟩🟩🟩🟩🟩",
    "🟦🟦🟦🟦⬛⬜⬜⬜⬜⬜⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬜⬜⬜⬜⬜⬛🟦🟦🟦🟦",
    "🟦🟦🟦⬛⬜⬜⬜⬜⬜⬛🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦⬛⬜⬜⬜⬜⬜⬛🟦🟦🟦",
    "🟦🟦⬛⬜⬜⬜⬜⬜⬛🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦⬛⬜⬜⬜⬜⬜⬛🟦🟦",
    "🟪⬛⬜⬜⬜⬜⬜⬛🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪⬛⬜⬜⬜⬜⬜⬛⬛⬜⬜⬜⬛⬛⬜⬜⬜⬜⬜⬛🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪⬛⬜⬜⬜⬜⬜⬛🟪",
    "⬛⬛⬛⬛⬛⬛⬛🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛⬛🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪⬛⬛⬛⬛⬛⬛⬛",
    "🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪🟪",
    "",
    "🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🍬🌸🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈🦄💖🍭🌈",
  ];

  for (const line of consoleLines) {
    console.log(line);
  }

  console.log("");
  console.log(`${"🌟".repeat(15)}`);
  console.log(`🌟`);
  console.log(`🌟  Version: ${appVersion}`);
  (isNonProd || isDev) &&
    console.log(
      `🌟  Mode: ${isNonProd ? "✅" : "❌"} / ${isDev ? "✅" : "❌"}`,
    );
  console.log(`🌟`);
  console.log(`${"🌟".repeat(15)}`);
  console.log("");
};

export const updateUserAnalytics = (user: SimpleUser) => {
  const analytics = getFirebaseAnalytics();

  if (!isSupported()) {
    return;
  }

  const userProps = {
    email: user.user_email,
    job_title: user.job_title,
    location: user.location,
    display_name: user.display_name,
    namespace: user.namespace,
    department: user.department,
  };
  setUserId(analytics, user.user_email);
  setUserProperties(analytics, userProps);

  datadogRum.setUser(userProps);
  datadogLogs.setUser(userProps);
};

export const KbRequestStatusMapping = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};

export const handleSignout = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ["me"] });
  window.location.href = "/?gcp-iap-mode=GCIP_SIGNOUT";
};

export const AppPermissions = {
  KB_PORTAL_CAN_ACCESS: "akbportal.application.can_access",
  KB_PORTAL_CAN_ACCESS_ADMIN: "akbportal.application.can_access_admin",
};

export const knowledgebaseVisibilityIconMap = {
  public: RiUserLine,
  private: RiLock2Line,
  hidden: RiLock2Line,
};

export const saveDateToLocalStorage = (key: string, date: Date): void => {
  localStorage.setItem(key, formatISO(date));
};

export const getDateFromLocalStorage = (key: string): Date | null => {
  const raw = localStorage.getItem(key);

  if (raw === null) {
    return null;
  }

  const parsed = parseISO(raw);
  return isValid(parsed) ? parsed : null;
};

export const KbCapbilities = [
  "Audit",
  "Consulting",
  "Tax",
  "Advisory",
  "Shared Services",
];

export const shouldShowSurvey = (): boolean => {
  /**
   * Figure out if we should show the survey popup.
   *
   * We define our survey periods as the ${surveyDurationDays} days
   * at the beginning of each quarter (Jan, Apr, Jul, Oct)
   */
  const today = new Date();

  const surveyStartDates = [
    new Date(today.getFullYear(), 0, 1),
    new Date(today.getFullYear(), 3, 1),
    new Date(today.getFullYear(), 6, 1),
    new Date(today.getFullYear(), 9, 1),
  ];

  /**
   * We can show the survey popup a maximum of ${surveyDurationDays}/${remindWaitDays}
   */
  const surveyDurationDays = 14;
  const remindWaitDays = 4;

  const surveyIntervals = surveyStartDates.map((startDate) =>
    interval(startDate, addDays(startDate, surveyDurationDays)),
  );

  /**
   * Filter the survey intervals to see if the current date is within one of the periods
   */
  const currentIntervals = surveyIntervals.filter((surveyInterval) =>
    isWithinInterval(today, surveyInterval),
  );

  const isSurveyDue = currentIntervals.length > 0;

  if (!isSurveyDue) {
    /**
     * We're outside the survey period
     */
    return false;
  }

  const currentInterval = currentIntervals[0];

  /**
   * Clickthrough date is the last time we clicked 'Take me there'
   */
  const clickthroughDate = getDateFromLocalStorage(clickthroughKey);

  /**
   * Remind date is the last time we clicked 'Remind me later' (or closed the popup)
   */
  const remindDate = getDateFromLocalStorage(remindKey);

  const cutoffDate = subDays(new Date(), remindWaitDays);

  if (remindDate === null && clickthroughDate === null) {
    /**
     * we haven't clicked anything, so we can show safely
     */
    return true;
  }

  /**
   * We should check this first as we don't want to show if we've clicked
   * though, regardless of the the remind date
   */
  if (
    clickthroughDate !== null &&
    isWithinInterval(clickthroughDate, currentInterval)
  ) {
    /**
     * we've already clicked through in this period  we shouldn't show
     */
    return false;
  }

  if (remindDate !== null && isBefore(remindDate, cutoffDate)) {
    /**
     * we clicked remind me before our cutoff date, we should show again
     */
    return true;
  }

  /**
   * Don't show if we haven't met any of the other conditions
   */
  return false;
};

export const runbookImageAspectRatio = ["1:1", "3:4", "4:3", "9:16", "16:9"];

export const runbookImageFormats = ["image/jpeg", "image/png"];
export const getRunbookOutputFormats = [
  {
    extension: "md",
    mime_type: "text/plain",
    display_name: "Text",
  },
  {
    extension: "docx",
    mime_type:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    display_name: "Word Document",
  },
  {
    extension: "pdf",
    mime_type: "application/octet-stream",
    display_name: "PDF Document",
  },
  // We don't need to distinguish between png & jpeg -
  // the download code will extract the right mime type & extension from the data url
  {
    extension: "png",
    mime_type: "image/png",
    display_name: "Image",
  },
];

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const inputOutputModalityTypes = [
  { label: "Text", value: "text" },
  { label: "file", value: "file" },
  { label: "Image", value: "image" },
  { label: "Audio", value: "audio" },
  { label: "Video", value: "video" },
];
