import { logError } from "common/errors";
import { getFirebaseAuth, getMicrosoftProvider } from "common/Firebase";
import {
  Auth as FirebaseAuth,
  signInWithRedirect,
  User,
  UserCredential,
} from "firebase/auth";
import { AuthenticationHandler, CIAPError, SelectedTenantInfo } from "gcip-iap";
import { Dispatch, SetStateAction } from "react";

export const signInHandler = (
  errorHandler: (error: Error | CIAPError) => void,
) => {
  signInWithRedirect(getFirebaseAuth(), getMicrosoftProvider()).catch(
    (error) => {
      logError(error as Error);
      errorHandler(error);
    },
  );

  return false;
};

export class AppAuthenticationHandler implements AuthenticationHandler {
  languageCode?: string | null | undefined;
  errorHandler: (error: Error | CIAPError) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;

  constructor(
    errorHandler: (error: Error | CIAPError) => void,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
  ) {
    this.errorHandler = errorHandler;
    this.setIsLoading = setIsLoading;
  }

  getAuth(_: string, __: string | null) {
    return getFirebaseAuth();
  }

  async startSignIn(
    _auth: FirebaseAuth,
    _?: SelectedTenantInfo | undefined,
  ): Promise<UserCredential> {
    return new Promise((_resolve, _reject) => {});
  }

  completeSignOut(): Promise<void> {
    return Promise.resolve();
  }

  handleError?(error: Error | CIAPError): void {
    this.errorHandler(error);
  }

  showProgressBar?(): void {
    this.setIsLoading(true);
  }

  hideProgressBar?(): void {
    this.setIsLoading(false);
  }

  processUser?(user: User): Promise<User> {
    return Promise.resolve(user);
  }
}
