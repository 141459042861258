import { datadogRum } from "@datadog/browser-rum";

import { isDev, Logger, logger } from "./util";

export const logError = (
  error: Error,
  _logger: Logger | null = null,
  context: object | undefined = undefined,
) => {
  const errorContext = "context" in error ? (error.context as object) : {};
  const fullContext: Record<string, unknown> = {
    ...errorContext,
    ...(context || {}),
  };

  (_logger || logger("ava", "red")).log(error, fullContext);

  if (!isDev) {
    datadogRum.addError(error, fullContext);
  }
};
